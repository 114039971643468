
import './App.less';
import { Button } from 'antd';
import {HashRouter as Router, Route,Switch} from 'react-router-dom';
import Home from './home/home'
import List from './list/list'

function App() {
  return (
    <Router>
        <Route exact path='/' component={Home} />
        <Route path='/list' component={List} />
    </Router> 
  );
}

export default App;
