import React, { Component } from 'react'
import './header.css';

class Header extends Component {
    render(){
        return(
            <div className="header">
                欢迎来到技术大叔分享心情   
            </div>
        );
    }
}

export default Header;
