import content1 from "./1.md";
import content2 from "./2.md";
import content3 from "./3.md";
import content4 from "./4.md";
import content5 from "./5.md";
import content6 from "./6.md";

const content = {
  '1':content1,
  '2':content2,
  '3':content3,
  '4':content4,
  '5':content5,
  '6':content6,
};
export default content;