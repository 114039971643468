import React from 'react'
import { Link } from 'react-router-dom';
import './home.css';

class Home extends React.Component{
  render() {
    return (
      <div className="index-bg">
        <div className="index-header">
          <div className="index-name">技术大叔分享心情</div>
          <div className="index-about"></div>
        </div>

        <div className = "enterInto">
          <Link to="/list">
            <div className = "enterdiv">点击进入</div>
          </Link>
        </div>
        <div className="index-footer">
          © 2022- 2022 · All Rights Reserved
          <a className="beian" href='https://beian.miit.gov.cn/#/Integrated/index' target="_blank">浙ICP备17045609号-3</a>
        </div>
      </div>
    )
  }
}

export default Home;