import React from 'react'
import { Link } from 'react-router-dom';
import { Layout, Menu, Breadcrumb } from 'antd';
import Header from '../header/header';
import ReactMarkdown from 'react-markdown';
import content from '../content/content';
import {
  DesktopOutlined,
  PieChartOutlined,
  FileOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import CodeBlock from '../content/CodeBlock';
import HeadingBlock from '../content/HeadingBlock';

const {Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

class List extends React.Component{

  state = {
    markdown:'',
  };

  constructor(props){
    super(props)
  }

  componentDidMount(){
    let md = content['1'];
    fetch(md)
      .then(res => res.text())
      .then(text => this.setState({ markdown: text }));
  }

  _itemPress = (item)=>{
    let md = content[item.key];
    fetch(md)
      .then(res => res.text())
      .then(text => this.setState({ markdown: text }));
  } 

  render() {
    let markdown= this.state.markdown;
    return (
      <Layout style={{ minHeight: '100vh' }}>
        <Sider>
          <div className="logo" />
          <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
            <Menu.Item key="1" onClick={this._itemPress}>nginx 配置二级域名</Menu.Item>
            <Menu.Item key="2" onClick={this._itemPress}>centos7自己搭建git服务</Menu.Item>
            <Menu.Item key="3" onClick={this._itemPress}>ios基本规范</Menu.Item>
            <Menu.Item key="4" onClick={this._itemPress}>html转pdf</Menu.Item>
            <Menu.Item key="5" onClick={this._itemPress}>Django 搭建blog</Menu.Item>
            <Menu.Item key="6" onClick={this._itemPress}>Centos7安装mysql</Menu.Item>
          </Menu>
        </Sider>
        <Layout className="site-layout">
          <Header className="site-layout-background" style={{ padding: 0 }} />
          <Content style={{ margin: '0 16px' }}>
            <ReactMarkdown 
              source={markdown} 
              escapeHtml={false}  //不进行HTML标签的转化
              renderers={{
                code:CodeBlock,
                header:HeadingBlock,
              }}
            />
          </Content>
          <Footer style={{ textAlign: 'center' }}>技术大叔分享心情 ©2022 Created by laozhou</Footer>
        </Layout>
      </Layout>
    );
  }
}
  
export default List;